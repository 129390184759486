import { useEffect } from "react";
import { useSearchParams, Outlet } from "react-router-dom";

import useBetterLocation from "./components/utils/useBetterLocation.js";

import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

import { useDispatch, useSelector } from "react-redux";

import {
  getUserFetch,
  tryUnauthLoginWithToken,
  tryQbrickLoginWithToken,
} from "./redux/actions/userActions";
import { initializeBrowserInfo } from "./redux/slices/browserSlice";

import "./components/css/App.css";
import "./components/css/Text.css";

import styles from "./components/css/App.module.css";

import ErrorBoundary from "./components/errorboundary/ErrorBoundary.js";
import { setUserTimeZone } from "./utils/userTimeZone.js";

import { parseUser } from "./utils/parseUser.js";
import useThemeStyle from "./components/themestyle/useThemeStyle.js";
import Cookies from "universal-cookie";

const App = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user.currentUser);
  const hasFetchedUser = useSelector((state) => state.user.hasFetchedUser);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const isAuthenticatedUnauth = useSelector(
    (state) => state.user.isAuthenticatedUnauth,
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const { search } = useBetterLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const authToken = queryParams.get("authToken");
    const qbrickToken = queryParams.get("token");

    if (typeof authToken === "string" && authToken?.length > 0) {
      // We have an auth token
      searchParams.delete("authToken");
      setSearchParams(searchParams);

      dispatch(tryUnauthLoginWithToken(authToken));
    } else if (typeof qbrickToken === "string" && qbrickToken?.length > 0) {
      // We have a qbrick token
      searchParams.delete("token");
      setSearchParams(searchParams);

      dispatch(tryQbrickLoginWithToken(qbrickToken));
    } else {
      // No token
      if (!isAuthenticated && !isAuthenticatedUnauth) {
        dispatch(getUserFetch());
      }
    }
  }, [isAuthenticated, isAuthenticatedUnauth, search, searchParams]);

  useEffect(() => {
    const { userName, userEmail, userId } = parseUser(currentUser);

    if (isAuthenticated || isAuthenticatedUnauth) {
      const sentryDataToSet = {
        id: currentUser._id,
        ip_address: "{{auto}}",
      };

      if (userName) {
        sentryDataToSet.username = userName;
      }

      if (userEmail) {
        sentryDataToSet.email = userEmail;
      }

      try {
        Sentry.setUser(sentryDataToSet);
      } catch {
        console.log("Could not set Sentry data");
      }

      try {
        ReactGA.set({ userId });
      } catch {
        console.log("Could not set Google Analytics data");
      }

      if (currentUser?.timeZone == null) {
        setUserTimeZone({ isRegularUser: currentUser.is_regular_user });
      }
    }
  }, [currentUser, isAuthenticated, isAuthenticatedUnauth]);

  useEffect(() => {
    dispatch(initializeBrowserInfo());
  }, [dispatch]);

  useThemeStyle();
  const oldCookies = new Cookies();
  oldCookies.set("connect.sid", "expired", {
    domain: ".univid.io",
    path: "/",
    expires: new Date(Date.now() - 2592000),
  });

  return (
    <ErrorBoundary>
      <div className={styles.AppContainer}>
        <div className={styles.HeaderContainer} id="header-container" />
        <div className={styles.ContentContainer}>
          {hasFetchedUser ? <Outlet /> : <div />}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Sentry.withProfiler(App);
